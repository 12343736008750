import { EditableData } from "@/types";

export function editEditableData<T>(
  baseData: EditableData<T> | null,
  newData: T,
  key: keyof EditableData<T>
) {
  if (baseData === null) {
    return { base: newData, user: newData };
  }
  const newObj = { ...baseData };
  if (key === "base") {
    newObj.base = newData;
    newObj.user = newData;
  } else {
    newObj.user = newData;
  }
  return newObj;
}

export function createEditableData<T>(value: T): EditableData<T> {
  return {
    base: value,
    user: value,
  };
}

export function revertEditableData<T>(data: EditableData<T>) {
  return { ...data, user: data.base };
}
