export const PUSH_HEADER_MAX_CHARS = 30;
export const PUSH_BODY_MAX_CHARS = 60;
export const BRIEF_LENGTH = 3000;

export const TOKEN_LIMIT = 4096;

export const MOBILE_RENDER_SIDE_LENGTH = 800;
export const DESKTOP_RENDER_SIDE_LENGTH = 2000;

export const MAX_DEVICE_HEIGHT = 800;

/* can be "gpt-3.5-turbo" or "gpt-4" */
export const MODEL = "gpt-3.5-turbo-1106";
