import { CopyIcon, RepeatIcon } from "@chakra-ui/icons";
import { HStack, Heading, Spacer, IconButton } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import UndoIcon from "./icons/BackIcon";

interface TextfieldHeaderBarProps {
  title: string;
  onCopy?: () => void;
  onRegenerate?: () => void;
  onRevert?: () => void;
  buttonsDisabled: boolean;
}

const TextfieldHeaderBar: FunctionComponent<TextfieldHeaderBarProps> = ({
  title,
  onCopy = () => null,
  onRegenerate = () => null,
  onRevert = () => null,
  buttonsDisabled,
}) => {
  return (
    <HStack w="full" spacing={4} px={2}>
      <Heading size="xs">{title}</Heading>
      <Spacer flex="1" />
      <IconButton
        icon={<CopyIcon />}
        variant="link"
        colorScheme="brand"
        size="xs"
        onClick={onCopy}
        disabled={buttonsDisabled}
        aria-label={"copy"}
      />
      <IconButton
        icon={<RepeatIcon />}
        variant="link"
        colorScheme="brand"
        size="xs"
        onClick={onRegenerate}
        disabled={buttonsDisabled}
        aria-label="reload"
      />
      <IconButton
        icon={<UndoIcon />}
        variant="link"
        colorScheme="brand"
        size="xs"
        onClick={onRevert}
        disabled={buttonsDisabled}
        aria-label="reset"
      />
    </HStack>
  );
};

export default TextfieldHeaderBar;
