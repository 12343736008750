import Image from "next/image";

import {
  Grid,
  HStack,
  Button,
  ChakraStyledOptions,
  VStack,
  StackProps,
  Box,
} from "@chakra-ui/react";
import Head from "next/head";
import { FC, useState } from "react";

import logoImage from "../../public/star.svg";
import MagicLink from "./ChakraLink";
import useMainStore from "@/stores/mainStore";
import { useRouter } from "next/router";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import logo from "./_res/logo-1.svg";

import starPattern from "../../public/starpattern.svg";

type LinkDef = {
  href: string;
  name: string;
  disabled?: boolean;
};
enum STAGE {
  NONE,
  META_DONE,
  DEVICES_DONE,
}

interface LayoutProps {
  children: React.ReactNode;
  footerChildren?: React.ReactNode;
  footerButtons?: React.ReactNode;
  header?: React.ReactNode;
  pageBefore?: LinkDef;
  pageNext?: LinkDef;
  nextDisabled?: boolean;
  footerStyles?: StackProps;
  doPulse?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  footerChildren,
  pageBefore,
  pageNext,
  nextDisabled,
  footerStyles,
  footerButtons,
  doPulse = false,
}) => {
  const [devMode, setDevMode] = useState(false);

  const { writeDevData, metaMessage, deviceMessages } = useMainStore();

  const computeStage = (): STAGE => {
    if (!metaMessage) return STAGE.NONE;
    if (!(deviceMessages.app && deviceMessages.mail)) return STAGE.META_DONE;
    return STAGE.DEVICES_DONE;
  };

  const stage = computeStage();
  const router = useRouter();

  const getStyleOptions = (
    link: string,
    itemStage: STAGE,
    currentStage: STAGE
  ): ChakraStyledOptions => {
    if (router.asPath === link) {
      return {
        textDecoration: "underline",
        textUnderlineOffset: 8,
      };
    }

    const isDisabled = currentStage < itemStage;
    if (isDisabled) {
      return {
        pointerEvents: "none",
        opacity: 0.5,
      };
    }
    return {};
  };

  return (
    <>
      <Head>
        <title>synapse</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Grid
        w="100vw"
        h="100vh"
        overflow="hidden"
        templateAreas={`
        "header"
        "content"
        "footer"
        `}
        textColor="white"
        templateRows="fit-content(40%) 1fr auto"
        templateColumns="1fr"
        bgPosition="center"
        bgSize="cover"
        position="fixed"
        bg="black"
        gap={8}
      >
        <HStack
          gridArea="header"
          w="full"
          p={4}
          textColor="white"
          spacing={8}
          align="center"
          zIndex="banner"
          justify="flex-start"
        >
          <Image
            src={logoImage}
            alt="logo"
            width={36}
            height={36}
            style={{ marginRight: -16 }}
          />
          {/* <Heading fontWeight="normal" fontFamily="CorpoA" fontSize="2xl">
            Synapse
          </Heading> */}
          <MagicLink href="/">
            <Image
              src={logo}
              width={138}
              height={27}
              style={{ marginRight: 8 }}
              alt="logo"
            />
          </MagicLink>
          <MagicLink href={"/"} {...getStyleOptions("/", 1, stage)}>
            Brief
          </MagicLink>
          <MagicLink
            href={"/devices"}
            {...getStyleOptions("/devices", STAGE.META_DONE, stage)}
          >
            Message
          </MagicLink>
          <MagicLink
            href={"/preview"}
            {...getStyleOptions("/preview", STAGE.DEVICES_DONE, stage)}
          >
            Preview
          </MagicLink>
          <MagicLink
            href={"/export"}
            {...getStyleOptions("/export", STAGE.DEVICES_DONE, stage)}
          >
            Export
          </MagicLink>
          {devMode && (
            <Button onClick={writeDevData} variant="ghost" fontWeight="normal">
              write dummy data
            </Button>
          )}
          <Button
            onClick={() => setDevMode((state) => !state)}
            fontWeight="normal"
            opacity={0}
          >
            dev mode
          </Button>
        </HStack>
        {children}
        <VStack gridArea="footer" pb={8} {...footerStyles}>
          <HStack color="white" spacing={4}>
            {pageBefore && (
              <Button
                aria-label="back"
                size="sm"
                rounded="full"
                onClick={() => router.push(pageBefore.href)}
                leftIcon={<ArrowBackIcon />}
                variant="outline"
              >
                back
              </Button>
            )}
            {pageNext && (
              <Button
                rounded="full"
                opacity={nextDisabled ? 0.5 : 1}
                ml="auto"
                onClick={
                  nextDisabled ? undefined : () => router.push(pageNext.href)
                }
                size="sm"
                disabled={nextDisabled}
                cursor={nextDisabled ? "not-allowed" : undefined}
                rightIcon={<ArrowForwardIcon />}
              >
                {pageNext.name}
              </Button>
            )}
            {footerButtons}
          </HStack>
          {footerChildren}
        </VStack>
        <Image
          alt=""
          src={starPattern}
          quality={100}
          fill
          style={{
            objectFit: "cover",
            zIndex: -10,
          }}
          className={doPulse ? "pulse" : ""}
        />
        <Box
          position="absolute"
          bgGradient="radial(rgba(0,0,0,0),rgba(0,0,0,0), rgba(0,0,0))"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="-10"
        />
      </Grid>
    </>
  );
};

export default Layout;
