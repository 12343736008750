import { create } from "zustand";
import { Result as MetaResult } from "@/types/meta";
import { EditableData, EditableDataKey, NullableData } from "@/types";
import { Result as DeviceResult } from "@/types/device";
import { ReqMode } from "@/types/gpt";
import { createEditableData, editEditableData } from "@/func/editable";

const meta = `Our latest safety feature, rear-axle steering, is designed to provide extra agility and maneuverability. Here are just a few of the ways the feature benefits you:
– It increases road safety by allowing for quick and easy maneuvering in tight spots.
– It provides more stability in high-speed corners and tight turns, ensuring smooth and responsive movement.
– With rear-axle steering, you have more control over the direction and speed of the vehicle, allowing you to drive faster without compromising cornering precision.
– It reduces fatigue and drivers mental stress while driving thanks to its improved handling characteristics.
– The feature is also simple and intuitive to use, enabling smarter and safer driving with minimal effort.`;

const app: DeviceResult = {
  title: "Safety & Maneuverability",
  message:
    "Our latest safety feature, rear-axle steering, helps keep you safe and offers agile maneuverability. Enjoy more control, stability and precise cornering.",
};

const mail: DeviceResult = {
  title: "Unlock New Levels of Driving Comfort",
  message:
    "Experience the newest innovation – rear-axle steering – with our latest safety feature, designed to give you the ease and precision of handling in any situation. Up your road safety by taking full control of your vehicle and enjoying smooth, high-speed corners. Keep fatigue and stress to a minimum, thanks to the feature's improved handling. With rear-axle steering, you can take your driving experience to the next level without extra effort and hassle.",
};

const devState: Partial<MainState> = {
  productDescription: "rear-axle steering",
  metaMessage: {
    base: meta,
    user: meta,
  },
  deviceMessages: {
    app: { base: app, user: app },
    mail: { base: mail, user: mail },
  },
};

const emptyDeviceState: DeviceMessagesState = {
  app: null,
  mail: null,
};

interface DeviceMessagesState {
  app: NullableData<EditableData<DeviceResult>>;
  mail: NullableData<EditableData<DeviceResult>>;
}

export type DeviceType = keyof DeviceMessagesState;

const invalidatedDevices: Partial<MainState> = {
  deviceMessages: { ...emptyDeviceState },
};

const invalidatedMeta: Partial<MainState> = {
  metaMessage: null,
  ...invalidatedDevices,
};

interface MainState {
  productDescription: string;
  setProductDescription: (m: string) => void;
  tonality: number;
  setTonality: (t: number) => void;
  useBulletPoints: boolean;
  setUseBulletPoints: (v: boolean) => void;
  additionalInfo: string;
  setAdditionalInfo: (v: string) => void;
  useOffline: ReqMode;
  setUseOffline: (r: ReqMode) => void;
  metaMessage: NullableData<EditableData<MetaResult>>;
  setMetaMessage: (m: NullableData<EditableData<MetaResult>>) => void;
  setMetaMessageField: (
    m: MetaResult,
    field: EditableDataKey<MetaResult>
  ) => void;
  revertMetaMessage: () => void;
  deviceMessages: DeviceMessagesState;
  setDeviceMessages: (m: DeviceMessagesState) => void;
  setMessageForDevice: (
    data: NullableData<EditableData<DeviceResult>>,
    device: DeviceType
  ) => void;
  setUserInputForDevice: (data: DeviceResult, device: DeviceType) => void;
  setBaseForDevice: (data: DeviceResult, device: DeviceType) => void;
  revertUserForDevice: (device: DeviceType) => void;
  writeDevData: () => void;
}

const useMainStore = create<MainState>((set, get) => ({
  productDescription: "",
  setProductDescription: (m) =>
    set({
      productDescription: m,
      ...invalidatedMeta,
    }),
  tonality: 5,
  setTonality: (n) =>
    set({
      tonality: n,
      ...invalidatedDevices,
    }),
  useBulletPoints: false,
  setUseBulletPoints: (v: boolean) =>
    set({
      useBulletPoints: v,
      ...invalidatedMeta,
    }),
  additionalInfo: "",
  setAdditionalInfo: (v: string) =>
    set({ additionalInfo: v, ...invalidatedMeta }),
  useOffline: "online",
  setUseOffline: (v) => set({ useOffline: v, ...invalidatedMeta }),
  metaMessage: null,
  setMetaMessage: (m) =>
    set({ metaMessage: m, deviceMessages: { ...emptyDeviceState } }),
  setMetaMessageField: (m, field) => {
    if (m === null) {
      return set({
        metaMessage: null,
        deviceMessages: { ...emptyDeviceState },
      });
    }
    const newData = editEditableData(get().metaMessage, m, field);
    return set({
      metaMessage: newData,
      deviceMessages: { ...emptyDeviceState },
    });
  },
  revertMetaMessage: () => {
    const m = get().metaMessage;
    if (!m) return;
    return set({ metaMessage: { ...m, user: m.base } });
  },

  deviceMessages: { ...emptyDeviceState },
  setDeviceMessages: (d) => set({ deviceMessages: d }),
  setMessageForDevice: (data, device) => {
    const newDeviceMessages = { ...get().deviceMessages, [device]: data };
    return set({ deviceMessages: newDeviceMessages });
  },

  setUserInputForDevice: (data, deviceName) => {
    const newDeviceMessages = get().deviceMessages;
    /* if the base changes we overwrite both base and user */
    const devKey = deviceName as DeviceType;

    if (!newDeviceMessages[devKey]?.base) {
      newDeviceMessages[devKey] = createEditableData<DeviceResult>(data);
      return set({ deviceMessages: newDeviceMessages });
    }

    if (!(newDeviceMessages && newDeviceMessages[devKey]?.base)) return;

    /* '!' DANGEROUS? */
    const newData = {
      /* i guess we can assume that the device is defined now, right?? */
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      base: newDeviceMessages[devKey]!.base,
      user: data,
    };
    newDeviceMessages[devKey] = newData;
    return set({ deviceMessages: newDeviceMessages });
  },
  setBaseForDevice: (data, device) => {
    const newDeviceMessages = { ...get().deviceMessages };
    /* if the base changes we overwrite both base and user */
    newDeviceMessages[device] = createEditableData<DeviceResult>(data);
    return set({ deviceMessages: newDeviceMessages });
  },
  revertUserForDevice: (device) => {
    const newDeviceMessages = { ...get().deviceMessages };
    if (!newDeviceMessages[device]?.base) return;
    /* if the base changes we overwrite both base and user */
    newDeviceMessages[device] = createEditableData<DeviceResult>(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newDeviceMessages[device]!.base
    );
    return set({ deviceMessages: newDeviceMessages });
  },
  writeDevData: () => set(devState),
}));

export default useMainStore;
