import { ReqMode } from "@/types/gpt";
import { create } from "zustand";

interface ConfigState {
  useOffline: ReqMode;
  setUseOffline: (r: ReqMode) => void;
}

const useConfigStore = create<ConfigState>((set) => ({
  useOffline: "online",
  setUseOffline: (v) => set({ useOffline: v }),
}));

export default useConfigStore;
