import { TOKEN_LIMIT } from "@/CONSTANTS";
import { toastDefaults } from "@/lib/defaults";
import { RequestState } from "@/types";
import {
  ReqMode,
  ChatResponse,
  SuccessfulChatResponse,
  SpecificErrorMessage,
} from "@/types/gpt";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useCallback, useState } from "react";

type ModeObj = {
  mode: ReqMode;
};

/* test commit  */
function useAiData<T, U>(
  endpoint: string,
  params: U & ModeObj,
  notificationName?: string
) {
  const [requestState, setRequestState] = useState<RequestState>("none");
  const toast = useToast();
  const [result, setResult] = useState<T | null>(null);

  const onSubmit = useCallback(() => {
    /*  const allFieldsFilled = Object.keys(params).every((key) => {
   const val = params[key as keyof U & ModeObj];
      if (val === undefined || val === null) {
        return false;
      }
      if (typeof val === "string") {
        return val.length > 0;
      }
      return true;
    }); */

    /* FIXME: add check if props are fine */
    /* TODO: add refetch if request failed */
    const allFieldsFilled = true;
    if (!allFieldsFilled) {
      toast({
        title: `Missing query parameters`,
        description: `Please fill in all the fields ${JSON.stringify(params)}`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setRequestState("loading");

    /* const mode: ReqMode = online ? "online" : "offline"; */

    axios
      .post(`/api/${endpoint}`, { ...params })
      .then((resp) => {
        const d = resp.data as ChatResponse<T>;
        if (d.success) {
          const dMesg = d as SuccessfulChatResponse<T>;

          const notificationDescription =
            notificationName || JSON.stringify(params);
          toast({
            ...toastDefaults,
            title: `Recommendation for ${notificationDescription.substring(
              0,
              40
            )}${notificationDescription.length > 40 ? "..." : ""} finished`,
            description: `${d.api.usage.total_tokens} tokens were used.`,
            status: "success",
          });
          setRequestState("success");
          setResult(dMesg.data);
        } else {
          toast({
            ...toastDefaults,
            title: "Recommendation failed",
            description: "",
            status: "error",
          });
          setRequestState("error");
        }
      })
      .catch((e) => {
        try {
          const msg = JSON.parse(
            e.response.data.message
          ) as SpecificErrorMessage;
          if (msg.reason === "length") {
            toast({
              ...toastDefaults,
              title: "Recommendation failed",
              description: `Token limit is too low: currently ${TOKEN_LIMIT} tokens`,
              status: "error",
            });
            setRequestState("error");
          } else {
            toast({
              ...toastDefaults,
              title: "Recommendation failed",
              description: JSON.stringify(e.response.data),
              status: "error",
            });
            setRequestState("error");
          }
        } catch (e) {
          setRequestState("error");
        }
      });
  }, [endpoint, params, toast, notificationName]);

  return { onSubmit, result, state: requestState };
}

export default useAiData;
